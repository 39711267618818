<template>
  <div class="sameWidth">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 20px 0 20px;">
      <el-breadcrumb-item :to="{ path: '/' }">首页1</el-breadcrumb-item>
      <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs type="card" v-model="formSearch.newsSortId" @tab-click="handleClick" style="margin:20px 0 0 20px;">
      <el-tab-pane v-for="(item,index) in articleSortData" :key="index" :label="item.name" :name="item.news_sort_id.toString()"></el-tab-pane>
    </el-tabs>
    <div v-for="(item,index) in tableData" :key="index" style="margin:0 0 0 20px;border:#E4E7ED 1px solid;border-radius: 5px;">
      <img :src="imgRealUrl(item.picture)" style="width:240px;vertical-align: middle;"/>
      <div style="display: inline-block;width:65%;text-align: left;padding-left: 14px;vertical-align: middle;">
        <h3>{{ item.news_name }}</h3>
        <h4 style="color: #909399;">{{ item.news_synopsis }}</h4>
        <p>
          <el-link type="info">{{ item.release_time }}</el-link>
          <el-link type="info" style="margin-left: 50px;"></el-link>
          <el-link type="primary" style="float: right;"><span @click="goTo(item)">查看详情</span></el-link>
        </p>
      </div>
    </div>
    <el-pagination
        style="margin: 20px;"
        background
        :current-page="tablePageInfo.currentPage"
        :page-sizes="[10, 20,50,100]"
        :page-size="tablePageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tablePageInfo.total">
    </el-pagination>
  </div>
</template>

<script>

export default {
  data() {
    return {
      screenWidth: 1300,
      articleSortData: [],
      formSearch: {
        name: '',
        state: 1,
        newsSortId: '',
        page: 1,
        limit: 10
      },
      tableData: [],
      tablePageInfo: {
        pageSize: 10,//每页的条数
        total: 0,//总条数
        currentPage: 1,//当前页
      },
    };
  },
  mounted() {
    var that = this;
    this.bus.$emit('headerImg', [{src: require('@/assets/images/bk/新闻.jpg'), href: ""}])
    this.$axios.post(this.baseUrl + 'admin/article/searchSort?state=1').then(response => {
      if (response.data.code == '200') {
        that.articleSortData = response.data.data.res;
      } else {
        that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
      }
    }).catch(function (error) {
      window.console.log(error)
    });//ajax请求失败
    this.screenWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', () => {
      this.screenWidth = document.documentElement.clientWidth;
    })
  },
  created() {
    this.getTableDate()
  },
  methods: {
    handleClick() {
      this.getTableDate()
    },
    imgRealUrl(url) {
      console.log(url)
      if (url.indexOf("https") > -1) {
        return url
      }
      return this.baseUrl2 + 'uploads/' + url;
    },//图片真实地址
    getTableDate: function (res) {
      var that = this;
      if (res != '') {
        that.formSearch.page = 1;
      }//点击查询，恢复第一页
      this.$axios.post(this.baseUrl + 'admin/article/searchArticle', that.formSearch).then(response => {
        if (response.data.code != '200') {
          that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
        } else {
          that.tablePageInfo.total = response.data.data.count;
          that.tableData = response.data.data.res
        }
      }).catch(function (error) {
        window.console.log(error)
      });//ajax请求失败
    },
    goTo(val) {
      this.$router.push({path: '/newsContent', query: {id: val.news_id}})
    }
  },
};
</script>